const importPage = (path) => import("@/pages/" + path);
const importLayout = (path) => import("@/layouts/" + path);
export default {

	path: "/IndustryTraining",
	component: () => importLayout("IndustryTrainingLayout"),
	children: [{
			path: "bannerList",
			components: {
				main: () => importPage("IndustryTraining/bannerList"),
			},
			meta: {
				title: "首页轮播图",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "bannerList",
			},
		},{
			path: "ad",
			components: {
				main: () => importPage("IndustryTraining/ad"),
			},
			meta: {
				title: "飘窗广告",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "ad",
			},
		},
		{
			path: "bannerEdit",
			components: {
				main: () => importPage("IndustryTraining/bannerEdit"),
			},
			meta: {
				title: "首页轮播图-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "bannerList",
			},
		},
		{
			path: "footerLinkList",
			components: {
				main: () => importPage("IndustryTraining/footerLinkList"),
			},
			meta: {
				title: "底部外链",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "footerLinkList",
			},
		},
		{
			path: "footerLinkEdit",
			components: {
				main: () => importPage("IndustryTraining/footerLinkEdit"),
			},
			meta: {
				title: "底部外链-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "footerLinkList",
			},
		},
		{
			path: "publicizeList",
			components: {
				main: () => importPage("IndustryTraining/publicizeList"),
			},
			meta: {
				title: "侧边快捷",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "publicize",
			},
		},
		{
			path: "publicizeEdit",
			components: {
				main: () => importPage("IndustryTraining/publicizeEdit"),
			},
			meta: {
				title: "侧边快捷-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "publicize",
			},
		},
		{
			path: "webInfo",
			components: {
				main: () => importPage("IndustryTraining/webInfo"),
			},
			meta: {
				title: "基本信息-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "webInfo",
				keepAlive: false,
			},
		},
		{
			path: "courseThemeList",
			components: {
				main: () => importPage("IndustryTraining/courseThemeList"),
			},
			meta: {
				title: "课程主题",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "courseThemeList",
			},
		},
		{
			path: "courseThemeEdit",
			components: {
				main: () => importPage("IndustryTraining/courseThemeEdit"),
			},
			meta: {
				title: "课程主题-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Auxiliary",
				tertiaryMenuId: "courseThemeList",
			},
		},
		{
			path: "Notice/videoList",
			components: {
				main: () => importPage("IndustryTraining/Notice/videoList"),
			},
			meta: {
				title: "宣传视频-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Notice",
				tertiaryMenuId: "videoList",
				keepAlive: false,
			},
		},
		{
			path: "Notice/videoEdit",
			components: {
				main: () => importPage("IndustryTraining/Notice/videoEdit"),
			},
			meta: {
				title: "宣传视频-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Notice",
				tertiaryMenuId: "videoList",
				keepAlive: false,
			},
		},
		{
			path: "Notice/noticeList",
			components: {
				main: () => importPage("IndustryTraining/Notice/noticeList"),
			},
			meta: {
				title: "培训通知-",
				primaryMenuId: "Industry",
				secondaryMenuId: "Notice",
				tertiaryMenuId: "noticeList",
				keepAlive: false,
			},
		},
		{
			path: "Notice/noticeEdit",
			components: {
				main: () => importPage("IndustryTraining/Notice/noticeEdit"),
			},
			meta: {
				title: "培训通知-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Notice",
				tertiaryMenuId: "noticeList",
				keepAlive: false,
			},
		},
		{
			path: "Question/questionList",
			components: {
				main: () => importPage("IndustryTraining/Question/questionList"),
			},
			meta: {
				title: "常见问题",
				primaryMenuId: "Industry",
				secondaryMenuId: "Question",
				tertiaryMenuId: "question",
				keepAlive: false,
			},
		},
		{
			path: "Question/questionEdit",
			components: {
				main: () => importPage("IndustryTraining/Question/questionEdit"),
			},
			meta: {
				title: "常见问题-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Question",
				tertiaryMenuId: "questionList",
				keepAlive: false,
			},
		},
		{
			path: "Lecturer/lecturerList",
			components: {
				main: () => importPage("IndustryTraining/Lecturer/lecturerList"),
			},
			meta: {
				title: "讲师管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Lecturer",
				tertiaryMenuId: "lecturerList",
				keepAlive: false,
			},
		},
		{
			path: "Lecturer/lecturerEdit",
			components: {
				main: () => importPage("IndustryTraining/Lecturer/lecturerEdit"),
			},
			meta: {
				title: "讲师管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Lecturer",
				tertiaryMenuId: "lecturerList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseList",
			components: {
				main: () => importPage("IndustryTraining/Course/courseList"),
			},
			meta: {
				title: "课程管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseEdit",
			components: {
				main: () => importPage("IndustryTraining/Course/courseEdit"),
			},
			meta: {
				title: "课程管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseList",
				keepAlive: false,
			},
		},
		{
			path: "Course/roomInfo",
			components: {
				main: () => importPage("IndustryTraining/Course/roomInfo"),
			},
			meta: {
				title: "直播间地址",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseList",
				keepAlive: false,
			},
		},
		{
			path: "Course/roomList",
			components: {
				main: () => importPage("IndustryTraining/Course/roomList"),
			},
			meta: {
				title: "直播间列表",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseList",
				keepAlive: false,
			},
		},
		{
			path: "Course/rollcall",
			components: {
				main: () => importPage("IndustryTraining/Course/rollcall"),
			},
			meta: {
				title: "签到信息",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseList",
				keepAlive: false,
			},
		},
		{
			path: "Course/rollcallInfo",
			components: {
				main: () => importPage("IndustryTraining/Course/rollcallInfo"),
			},
			meta: {
				title: "签到用户信息",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseList",
				keepAlive: false,
			},
		},
		{
			path: "Course/coursecategoryList",
			components: {
				main: () => importPage("IndustryTraining/Course/coursecategoryList"),
			},
			meta: {
				title: "课程类别管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "coursecategoryList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseCheckIn",
			components: {
				main: () => importPage("IndustryTraining/Course/courseCheckIn"),
			},
			meta: {
				title: "签到信息",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseCheckIn",
				keepAlive: false,
			},
		},
		{
			path: "Course/coursecategoryEdit",
			components: {
				main: () => importPage("IndustryTraining/Course/coursecategoryEdit"),
			},
			meta: {
				title: "课程类别管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "coursecategoryList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseCatalogKCList",
			components: {
				main: () => importPage("IndustryTraining/Course/courseCatalogKCList"),
			},
			meta: {
				title: "课程目录管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseCatalogKCList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseCatalogKCEdit",
			components: {
				main: () => importPage("IndustryTraining/Course/courseCatalogKCEdit"),
			},
			meta: {
				title: "课程目录管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseCatalogKCList",
				keepAlive: false,
			},
		},
		{
			path: "Course/coursesectionsKCList",
			components: {
				main: () => importPage("IndustryTraining/Course/coursesectionsKCList"),
			},
			meta: {
				title: "课程章节管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "coursesectionsKCList",
				keepAlive: false,
			},
		},
		{
			path: "Course/coursesectionsKCEdit",
			components: {
				main: () => importPage("IndustryTraining/Course/coursesectionsKCEdit"),
			},
			meta: {
				title: "课程章节管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "coursesectionsKCList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseDiscountList",
			components: {
				main: () => importPage("IndustryTraining/Course/courseDiscountList"),
			},
			meta: {
				title: "折扣管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseDiscountList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseDiscountEdit",
			components: {
				main: () => importPage("IndustryTraining/Course/courseDiscountEdit"),
			},
			meta: {
				title: "折扣管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "coursesectionsKCList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseTimeSpanList",
			components: {
				main: () => importPage("IndustryTraining/Course/courseTimeSpanList"),
			},
			meta: {
				title: "直播时段管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseTimeSpanList",
				keepAlive: false,
			},
		},
		{
			path: "Course/courseTimeSpanEdit",
			components: {
				main: () => importPage("IndustryTraining/Course/courseTimeSpanEdit"),
			},
			meta: {
				title: "直播时段管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Course",
				tertiaryMenuId: "courseTimeSpanList",
				keepAlive: false,
			},
		},
		{
			path: "Area/areaList",
			components: {
				main: () => importPage("IndustryTraining/Area/areaList"),
			},
			meta: {
				title: "区域管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Unit",
				tertiaryMenuId: "areaList",
				keepAlive: false,
			},
		},
		{
			path: "Area/areaEdit",
			components: {
				main: () => importPage("IndustryTraining/Area/areaEdit"),
			},
			meta: {
				title: "区域管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Unit",
				tertiaryMenuId: "areaList",
				keepAlive: false,
			},
		},
		{
			path: "Unit/unitList",
			components: {
				main: () => importPage("IndustryTraining/Unit/unitList"),
			},
			meta: {
				title: "会员单位管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Unit",
				tertiaryMenuId: "unitList",
				keepAlive: false,
			},
		},
		{
			path: "Unit/unitEdit",
			components: {
				main: () => importPage("IndustryTraining/Unit/unitEdit"),
			},
			meta: {
				title: "会员单位管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Unit",
				tertiaryMenuId: "unitList",
				keepAlive: false,
			},
		},
		{
			path: "Order/orderList",
			components: {
				main: () => importPage("IndustryTraining/Order/orderList"),
			},
			meta: {
				title: "订单管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "orderList",
				keepAlive: false,
			},
		},
		{
			path: "Order/enroll",
			components: {
				main: () => importPage("IndustryTraining/Order/enroll"),
			},
			meta: {
				title: "学员报名信息",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "enroll",
				keepAlive: false,
			},
		},
		{
			path: "Order/orderEdit",
			components: {
				main: () => importPage("IndustryTraining/Order/orderEdit"),
			},
			meta: {
				title: "订单管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "orderList",
				keepAlive: false,
			},
		},		
		{
			path: "Order/orderset",
			components: {
				main: () => importPage("IndustryTraining/Order/orderset"),
			},
			meta: {
				title: "订单设置",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "orderset",
				keepAlive: false,
			},
		},
		{
			path: "Order/ordershList",
			components: {
				main: () => importPage("IndustryTraining/Order/ordershList"),
			},
			meta: {
				title: "售后订单管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "ordershList",
				keepAlive: false,
			},
		},
		{
			path: "Order/ordershEdit",
			components: {
				main: () => importPage("IndustryTraining/Order/ordershEdit"),
			},
			meta: {
				title: "售后订单管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "ordershList",
				keepAlive: false,
			},
		},
		{
			path: "Order/ordershChuli",
			components: {
				main: () => importPage("IndustryTraining/Order/ordershChuli"),
			},
			meta: {
				title: "售后订单处理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "ordershList",
				keepAlive: false,
			},
		},
		{
			path: "Order/orderAudit",
			components: {
				main: () => importPage("IndustryTraining/Order/orderAudit"),
			},
			meta: {
				title: "订单管理-审核",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "orderList",
				keepAlive: false,
			},
		},
		{
			path: "Order/invoiceList",
			components: {
				main: () => importPage("IndustryTraining/Order/invoiceList"),
			},
			meta: {
				title: "发票管理管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "invoiceList",
				keepAlive: false,
			},
		},
		{
			path: "Order/invoiceEdit",
			components: {
				main: () => importPage("IndustryTraining/Order/invoiceEdit"),
			},
			meta: {
				title: "发票管理管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "invoiceList",
				keepAlive: false,
			},
		},
		{
			path: "Order/invoiceKaipiao",
			components: {
				main: () => importPage("IndustryTraining/Order/invoiceKaipiao"),
			},
			meta: {
				title: "发票管理-开票",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "invoiceList",
				keepAlive: false,
			},
		},
		{
			path: "Order/invoiceTuipiao",
			components: {
				main: () => importPage("IndustryTraining/Order/invoiceTuipiao"),
			},
			meta: {
				title: "发票管理-退票",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "invoiceList",
				keepAlive: false,
			},
		},
		{
			path: "Order/subOrderList",
			components: {
				main: () => importPage("IndustryTraining/Order/subOrderList"),
			},
			meta: {
				title: "子订单管理",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "orderList",
				keepAlive: false,
			},
		},
		{
			path: "Order/subOrderEdit",
			components: {
				main: () => importPage("IndustryTraining/Order/subOrderEdit"),
			},
			meta: {
				title: "子订单管理-编辑",
				primaryMenuId: "Industry",
				secondaryMenuId: "Order",
				tertiaryMenuId: "orderList",
				keepAlive: false,
			},
		},

		{
			path: "studentList",
			components: {
			  main: () => importPage("IndustryTraining/studentList"),
			},
			meta: {
			  title: "学员管理",
			  primaryMenuId: "Industry",
			  secondaryMenuId: "Student",
			  tertiaryMenuId: "studentList",
			},
		  },
		  {
			path: "studentEdit",
			components: {
			  main: () => importPage("IndustryTraining/studentEdit"),
			},
			meta: {
			  title: "学员管理-编辑",
			  primaryMenuId: "Industry",
			  secondaryMenuId: "Student",
			  tertiaryMenuId: "studentList",
			},
		  },
		  {
			path: "studentGroupList",
			components: {
			  main: () => importPage("IndustryTraining/studentGroupList"),
			},
			meta: {
			  title: "学员分组管理",
			  primaryMenuId: "Industry",
			  secondaryMenuId: "Student",
			  tertiaryMenuId: "studentGroupList",
			},
		  },
		  {
			path: "studentGroupEdit",
			components: {
			  main: () => importPage("IndustryTraining/studentGroupEdit"),
			},
			meta: {
			  title: "学员分组管理-编辑",
			  primaryMenuId: "Industry",
			  secondaryMenuId: "Student",
			  tertiaryMenuId: "studentGroupList",
			},
		  },
		  {
			  path: "Unit/unitinfoList",
			  components: {
				  main: () => importPage("IndustryTraining/Unit/unitinfoList"),
			  },
			  meta: {
				  title: "单位管理",
				  primaryMenuId: "Industry",
				  secondaryMenuId: "unitinfoList",
				  tertiaryMenuId: "unitinfoList",
				  keepAlive: false,
			  },
		  },
		  {
			  path: "Unit/unitinfoEdit",
			  components: {
				  main: () => importPage("IndustryTraining/Unit/unitinfoEdit"),
			  },
			  meta: {
				  title: "单位管理-编辑",
				  primaryMenuId: "Industry",
				  secondaryMenuId: "unitinfoList",
				  tertiaryMenuId: "unitinfoList",
				  keepAlive: false,
			  },
		  },
		  {
			path: "Unit/usedList",
			components: {
			  main: () => importPage("IndustryTraining/Unit/usedList"),
			},
			meta: {
			  title: "已使用的学员",
			  primaryMenuId: "training",
			  secondaryMenuId: "unitinfoList",
			  tertiaryMenuId: "unitinfoList",
			},
		  },
		  {
			path: "Order/orderhuanxyList",
			components: {
			  main: () => importPage("IndustryTraining/Order/orderhuanxyList"),
			},
			meta: {
			  title: "更换学员管理",
			  primaryMenuId: "training",
			  secondaryMenuId: "orderList",
			  tertiaryMenuId: "orderhuanxyList",
			},
		  },
		  {
			path: "Order/orderhuanxyEdit",
			components: {
			  main: () => importPage("IndustryTraining/Order/orderhuanxyEdit"),
			},
			meta: {
			  title: "更换学员管理-编辑",
			  primaryMenuId: "training",
			  secondaryMenuId: "orderList",
			  tertiaryMenuId: "orderhuanxyList",
			},
		  },
		  {
			path: "Order/orderhuanxyShenhe",
			components: {
			  main: () => importPage("IndustryTraining/Order/orderhuanxyShenhe"),
			},
			meta: {
			  title: "更换学员审核",
			  primaryMenuId: "training",
			  secondaryMenuId: "orderList",
			  tertiaryMenuId: "orderhuanxyList",
			},
		  },
		  {
			path: "Order/replacingstudentslogList",
			components: {
			  main: () => importPage("IndustryTraining/Order/replacingstudentslogList"),
			},
			meta: {
			  title: "更换学员记录",
			  primaryMenuId: "Industry",
			  secondaryMenuId: "Order",
			  tertiaryMenuId: "orderList",
			},
		  },
		  {
			path: "Order/replacingstudentslogEdit",
			components: {
			  main: () => importPage("IndustryTraining/Order/replacingstudentslogEdit"),
			},
			meta: {
			  title: "更换学员记录-查看",
			  primaryMenuId: "Industry",
			  secondaryMenuId: "Order",
			  tertiaryMenuId: "orderList",
			},
		  },
		  {
			path: "Course/courseshipinList",
			components: {
			  main: () => importPage("IndustryTraining/Course/courseshipinList"),
			},
			meta: {
			  title: "课程视频管理",
			  primaryMenuId: "training",
			  secondaryMenuId: "Course",
			  tertiaryMenuId: "courseshipinList",
			},
		  },
		  {
			path: "Course/courseshipinEdit",
			components: {
			  main: () => importPage("IndustryTraining/Course/courseshipinEdit"),
			},
			meta: {
			  title: "课程视频管理-编辑",
			  primaryMenuId: "training",
			  secondaryMenuId: "Course",
			  tertiaryMenuId: "courseshipinList",
			},
		  },
		  {
			path: "Course/annexList",
			components: {
			  main: () => importPage("IndustryTraining/Course/annexList"),
			},
			meta: {
			  title: "课程附件管理",
			  primaryMenuId: "training",
			  secondaryMenuId: "Course",
			  tertiaryMenuId: "annexList",
			},
		  },
		  {
			path: "Course/annexEdit",
			components: {
			  main: () => importPage("IndustryTraining/Course/annexEdit"),
			},
			meta: {
			  title: "课程附件管理-编辑",
			  primaryMenuId: "training",
			  secondaryMenuId: "Course",
			  tertiaryMenuId: "annexList",
			},
		  },
		  {
			  path: "Course/playLogList",
			  components: {
				main: () => importPage("IndustryTraining/Course/playLogList"),
			  },
			  meta: {
				title: "课程视频播放记录",
				primaryMenuId: "Industry",
				secondaryMenuId: "Student",
				tertiaryMenuId: "playLogList",
			  },
			}
	]

}