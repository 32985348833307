import { createApp } from "vue";

import App from "./App.vue";

const app = createApp(App);

import "./assets/style/global.scss";
import "./assets/style/main.scss";

/** Element Plus */
import ElementPlus from "element-plus";
import { ElMessage } from "element-plus";
import "element-plus/dist/index.css";

app.use(ElementPlus, {
  size: "default",
});

/** Element Plus Icon */
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

/** material icons */
import "material-icons/iconfont/material-icons.css";

/** store */
import store from "./store";
/** router */
import router from "./router.js";

/** 菜单选中 */
router.beforeEach(async (to, from, next) => {
  next();

  // 网页标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 选中菜单
  // console.log("primaryMenuId:", to.meta.primaryMenuId);
  if (to.meta.primaryMenuId) {
    store.state.common.primaryMenuId = to.meta.primaryMenuId;
  }
  // console.log("store.state.common.primaryMenuId", store.state.common.primaryMenuId);
  if (to.meta.secondaryMenuId) {
    store.state.common.secondaryMenuId = to.meta.secondaryMenuId;
  }
  // console.log("store.state.common.tertiaryMenuId", store.state.common.tertiaryMenuId);
  if (to.meta.tertiaryMenuId) {
    store.state.common.tertiaryMenuId = to.meta.tertiaryMenuId;
  }
});

/** 路由跳转 */
app.config.globalProperties.$pushTo = function (url) {
  if (router.path != url) {
    router.push(url);
  }

  // 页面重置到顶部
  document.body.scrollIntoView();
};

app.config.globalProperties.$replaceTo = function (url) {
  // console.log('this.$route.path', this.$route.path);
  // console.log('url', url);
  if (router.path != url) {
    router.replace(url);
  }
  // 页面重置到顶部
  document.body.scrollIntoView();
};

/** 消息提醒 */
app.config.globalProperties.$successMessage = function (content) {
  ElMessage({
    message: content,
    type: "success",
    grouping: true,
  });
};

app.config.globalProperties.$errorMessage = function (content) {
  ElMessage({
    message: content,
    type: "error",
    grouping: true,
  });
};

//默认页码
app.config.globalProperties.$pageSize = function () {
  return 25
};

import * as managerApi from "@/api/manager";

//无权限判断 
app.config.globalProperties.$AuthorityColumn = function (code, siteId = 1) {
  var ModulePermission = localStorage.getItem("modulePermission");
  var KoqianPermission = localStorage.getItem("KoqianPermission");
  var HypxPermission = localStorage.getItem("HypxPermission");
  var WebPermission = localStorage.getItem("WebPermission");
  var roleId = localStorage.getItem("roleId");
  if (roleId == 32) {
    return true
  }
  var Permission = ''
  if (siteId == -1) {
    Permission = ModulePermission
  }
  else if (siteId == 1) {
    Permission = KoqianPermission

  } else if (siteId == 2) {
    Permission = HypxPermission

  } else if (siteId == 3) {
    Permission = WebPermission
  }
  if (Permission != null && Permission != '' & Permission != undefined) {
    if (("*" + Permission.toLowerCase() + "*").indexOf("*" + code.toLowerCase() + "*") > -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

//验证登录
app.config.globalProperties.$VerifyLogin = function () {
  var tokenStr = localStorage.getItem("managertoken");

  if (!tokenStr) {
    this.$pushTo("/manager/login");
    return;
  }

  managerApi
    .ValiToken({
      tokenStr: tokenStr,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      if (err.code == 406) {
        this.$pushTo("/manager/login");
      }
    });
};

import axiosJson from 'axios'
/* 读取外部配置文件 */
axiosJson.get(location.origin + '/config.json').then((res) => {
  app.config.globalProperties.$editorServerUrl = res.data.editorServerUrl;
  app.config.globalProperties.$apiUrl = res.data.apiUrl;
})

/** 自定义的常量 */
import "./utils/constant/enumeration";
/** 自定义的数据类型扩展 */
import "./utils/extension/stringExtension";
import "./utils/extension/numberExtension";

//自定义插件,提供全局调用资源
import plugins from "./plugins";

app.use(store).use(router).use(plugins).mount("#app");
