//i18n加载本地化
import { createI18n } from 'vue-i18n/index.mjs'
import { messages } from '@/utils/constant/i18n/local'; //自定义本地化页面资源

const i18n = new createI18n({
    warnHtmlMessage: false,//消除i18n中存在html标签而产生的XSS攻击警告
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true, // 全局注册$t方法
    messages: messages,
    fallbackLocale: 'zh_cn',
    locale: localStorage.getItem('eip_plus_lang') || 'zh_cn'
});

import VueUeditorWrap from 'vue-ueditor-wrap';

//引入element-plus 本地化语言包，列表分页控件配合i18n插件一齐使用
import zhCn from "element-plus/es/locale/lang/zh-cn";
import English from "element-plus/es/locale/lang/en";

export default {
    install(app) {

        /******************第三方库引用*******************/
        //i18n
        app.use(i18n);

        //UEditor
        app.use(VueUeditorWrap);

        /******************自定义全局组件*******************/


        /******************全局方法*******************/
        //组件注入方式，inject: ['方法名'] ，可在data()中访问

        //调用element-local切换本地化资源
        app.config.globalProperties.$getElementLocal = function (lang) {
            switch (lang) {
                case "zh_cn":
                    return zhCn;
                case "en":
                    return English;
                default:
                    return zhCn;
            }
        };

        //根据i18n路径返回验证消息
        app.provide('ruleMessage', function (i18nPath, format) {
            return this.$t(i18nPath, format);
        });
    }
}
