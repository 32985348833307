import { request } from "@/utils/request";

export function Login(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/manager/Login",
    params,
  });
}

export function GetCurrLogin(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/manager/GetCurrLogin",
    params,
  });
}


export function ValiToken(params = {}) {
  return request({
    method: "get",
    url: "/adminApi/manager/ValiToken",
    params,
  });
}

export function GetTokenState(params = {}) {
  return request({
    method: "get",
    url: "/adminApi/manager/GetTokenState",
    params,
  });
}

export function Add(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/manager/Add",
    params,
  });
}

export function Modify(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/manager/Modify",
    params,
  });
}

export function Del(params = {}) {
  return request({
    method: "post",
    url: "/adminApi/manager/Del",
    params,
  });
}

export function GetItemById(params = {}) {
  return request({
    method: "get",
    url: "/adminApi/manager/GetItemById",
    params,
  });
}

export function GetPage(params = {}) {
  return request({
    method: "get",
    url: "/adminApi/manager/GetPage",
    params,
  });
}

export function Enable(params = {}){
  return request({
    method: "post",
    url: "/adminApi/manager/Enable",
    params,
  });
}


export function AuthorityMenu(params = {}) {
  return request({
    method: "get",
    url: "/adminApi/manager/AuthorityMenu",
    params,
  });
}

export function AuthorityShowHide(params = {}) {
  return request({
    method: "get",
    url: "/adminApi/manager/AuthorityShowHide",
    params,
  });
}