const importPage = (path) => import("@/pages/" + path);
const importLayout = (path) => import("@/layouts/" + path);
export default{
	
	  path: "/webSite",
	  component: () => importLayout("webSiteLayout"),
	  children: [
	    {
	      path: "aboutProfilegk",
	      components: {
	        main: () => importPage("webSite/aboutProfilegk"),
	      },
	      meta: {
	        title: "公司概况-介绍",
	        primaryMenuId: "webSite",
	        secondaryMenuId: "aboutProfilegk",
	        tertiaryMenuId: "aboutProfilegk",
	      },
	    },
		{
			path: "aboutProfileL1",
			components: {
			  main: () => importPage("webSite/aboutProfileL1"),
			},
			meta: {
			  title: "公司概况-列表1",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutProfileL1",
			},
		  },
		  {
			path: "aboutProfileE1",
			components: {
			  main: () => importPage("webSite/aboutProfileE1"),
			},
			meta: {
			  title: "公司概况-列表1编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutProfileL1",
			},
		  },
		  {
			path: "aboutProfileL2",
			components: {
			  main: () => importPage("webSite/aboutProfileL2"),
			},
			meta: {
			  title: "公司概况-列表2",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutProfileL2",
			},
		  },
		  {
			path: "aboutProfileE2",
			components: {
			  main: () => importPage("webSite/aboutProfileE2"),
			},
			meta: {
			  title: "公司概况-列表2编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutProfileL2",
			},
		  },
		  {
			path: "aboutProfileL3",
			components: {
			  main: () => importPage("webSite/aboutProfileL3"),
			},
			meta: {
			  title: "公司概况-团队介绍",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutProfileL3",
			},
		  },
		  {
			path: "aboutProfileE3",
			components: {
			  main: () => importPage("webSite/aboutProfileE3"),
			},
			meta: {
			  title: "公司概况-团队介绍编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutProfileL3",
			},
		  },
		  {
			path: "aboutHistory",
			components: {
			  main: () => importPage("webSite/aboutHistory"),
			},
			meta: {
			  title: "关于我们-发展历程",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutHistory",
			},
		  },
		  {
			path: "aboutHistoryE",
			components: {
			  main: () => importPage("webSite/aboutHistoryE"),
			},
			meta: {
			  title: "关于我们-发展历程编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutHistory",
			},
		  },

		  {
			path: "aboutStructure",
			components: {
			  main: () => importPage("webSite/aboutStructure"),
			},
			meta: {
			  title: "公司概况-组织架构",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutStructure",
			},
		  },
		  {
			path: "aboutStructureE",
			components: {
			  main: () => importPage("webSite/aboutStructureE"),
			},
			meta: {
			  title: "公司概况-组织架构编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutStructure",
			},
		  },
		  {
			path: "aboutRecruit",
			components: {
			  main: () => importPage("webSite/aboutRecruit"),
			},
			meta: {
			  title: "公司概况-人才招聘",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutRecruit",
			},
		  },
		  {
			path: "aboutRecruitE",
			components: {
			  main: () => importPage("webSite/aboutRecruitE"),
			},
			meta: {
			  title: "公司概况-人才招聘编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutRecruit",
			},
		  },
		  {
			path: "aboutLwxmgk",
			components: {
			  main: () => importPage("webSite/aboutLwxmgk"),
			},
			meta: {
			  title: "联系我们-介绍",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutLwxmgk",
			},
		  },
		  {
			path: "aboutLxwm",
			components: {
			  main: () => importPage("webSite/aboutLxwm"),
			},
			meta: {
			  title: "联系我们-子公司",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutLxwm",
			},
		  },
		  {
			path: "aboutLxwmE",
			components: {
			  main: () => importPage("webSite/aboutLxwmE"),
			},
			meta: {
			  title: "联系我们-子公司编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "aboutProfilegk",
			  tertiaryMenuId: "aboutLxwm",
			},
		  },
		  {
			path: "news",
			components: {
			  main: () => importPage("webSite/news"),
			},
			meta: {
			  title: "新闻资讯列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "news",
			  tertiaryMenuId: "news",
			},
		  },
		  {
			path: "newsE",
			components: {
			  main: () => importPage("webSite/newsE"),
			},
			meta: {
			  title: "新闻资讯列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "news",
			  tertiaryMenuId: "news",
			},
		  },
		  {
			path: "newsType",
			components: {
			  main: () => importPage("webSite/newsType"),
			},
			meta: {
			  title: "新闻资讯分类列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "news",
			  tertiaryMenuId: "newsType",
			},
		  },
		  {
			path: "newsTypeE",
			components: {
			  main: () => importPage("webSite/newsTypeE"),
			},
			meta: {
			  title: "新闻资讯分类列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "news",
			  tertiaryMenuId: "newsType",
			},
		  },

		  {
			path: "industry",
			components: {
			  main: () => importPage("webSite/industry"),
			},
			meta: {
			  title: "行业风采列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "industry",
			  tertiaryMenuId: "industry",
			},
		  },
		  {
			path: "industryE",
			components: {
			  main: () => importPage("webSite/industryE"),
			},
			meta: {
			  title: "行业风采列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "industry",
			  tertiaryMenuId: "industry",
			},
		  },
		  {
			path: "industryType",
			components: {
			  main: () => importPage("webSite/industryType"),
			},
			meta: {
			  title: "行业风采分类列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "industry",
			  tertiaryMenuId: "industryType",
			},
		  },
		  {
			path: "industryTypeE",
			components: {
			  main: () => importPage("webSite/industryTypeE"),
			},
			meta: {
			  title: "行业风采分类列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "industry",
			  tertiaryMenuId: "industryType",
			},
		  },


		  {
			path: "hyfwType",
			components: {
			  main: () => importPage("webSite/hyfwType"),
			},
			meta: {
			  title: "行业服务分类列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "hyfw",
			  tertiaryMenuId: "hyfwType",
			},
		  },
		  {
			path: "hyfwTypeE",
			components: {
			  main: () => importPage("webSite/hyfwTypeE"),
			},
			meta: {
			  title: "行业服务分类列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "hyfw",
			  tertiaryMenuId: "hyfwType",
			},
		  },
		  {
			path: "hyfwYear",
			components: {
			  main: () => importPage("webSite/hyfwYear"),
			},
			meta: {
			  title: "行业服务年份列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "hyfw",
			  tertiaryMenuId: "hyfwYear",
			},
		  },
		  {
			path: "hyfwYearE",
			components: {
			  main: () => importPage("webSite/hyfwYearE"),
			},
			meta: {
			  title: "行业服务年份列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "hyfw",
			  tertiaryMenuId: "hyfwYear",
			},
		  },
		  {
			path: "hyfw",
			components: {
			  main: () => importPage("webSite/hyfw"),
			},
			meta: {
			  title: "行业服务列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "hyfw",
			  tertiaryMenuId: "hyfw",
			},
		  },
		  {
			path: "hyfwE",
			components: {
			  main: () => importPage("webSite/hyfwE"),
			},
			meta: {
			  title: "行业服务列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "hyfw",
			  tertiaryMenuId: "hyfw",
			},
		  },

		  {
			path: "cooperationType",
			components: {
			  main: () => importPage("webSite/cooperationType"),
			},
			meta: {
			  title: "商业合作分类列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "cooperation",
			  tertiaryMenuId: "cooperationType",
			},
		  },
		  {
			path: "cooperationTypeE",
			components: {
			  main: () => importPage("webSite/cooperationTypeE"),
			},
			meta: {
			  title: "商业合作分类列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "cooperation",
			  tertiaryMenuId: "cooperationType",
			},
		  },
		  {
			path: "cooperationlx",
			components: {
			  main: () => importPage("webSite/cooperationlx"),
			},
			meta: {
			  title: "联系人方式",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "cooperation",
			  tertiaryMenuId: "cooperationlx",
			},
		  },
		  {
			path: "cooperation",
			components: {
			  main: () => importPage("webSite/cooperation"),
			},
			meta: {
			  title: "商业合作列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "cooperation",
			  tertiaryMenuId: "cooperation",
			},
		  },
		  {
			path: "cooperationE",
			components: {
			  main: () => importPage("webSite/cooperationE"),
			},
			meta: {
			  title: "商业合作列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "cooperation",
			  tertiaryMenuId: "cooperation",
			},
		  },

		  {
			path: "webInfo",
			components: {
			  main: () => importPage("webSite/webInfo"),
			},
			meta: {
			  title: "网站基本信息",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "webInfo",
			},
		  },
		  {
			path: "aboutUs",
			components: {
			  main: () => importPage("webSite/aboutUs"),
			},
			meta: {
			  title: "关于我们",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "aboutUs",
			},
		  },
		  {
			path: "contactUs",
			components: {
			  main: () => importPage("webSite/contactUs"),
			},
			meta: {
			  title: "联系我们",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "contactUs",
			},
		  },
		  {
			path: "legalNotice",
			components: {
			  main: () => importPage("webSite/legalNotice"),
			},
			meta: {
			  title: "法律声明",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "legalNotice",
			},
		  },
		  {
			path: "linkList",
			components: {
			  main: () => importPage("webSite/linkList"),
			},
			meta: {
			  title: "友情链接列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "linkList",
			},
		  },
		  {
			path: "linkEdit",
			components: {
			  main: () => importPage("webSite/linkEdit"),
			},
			meta: {
			  title: "友情链接列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "linkList",
			},
		  },

		  {
			path: "yewubuju",
			components: {
			  main: () => importPage("webSite/yewubuju"),
			},
			meta: {
			  title: "业务布局列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "yewubuju",
			},
		  },
		  {
			path: "yewubujuE",
			components: {
			  main: () => importPage("webSite/yewubujuE"),
			},
			meta: {
			  title: "业务布局列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "yewubuju",
			},
		  },
		  {
			path: "bannerList",
			components: {
			  main: () => importPage("webSite/bannerList"),
			},
			meta: {
			  title: "轮播图列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "bannerList",
			},
		  },
		  {
			path: "bannerEdit",
			components: {
			  main: () => importPage("webSite/bannerEdit"),
			},
			meta: {
			  title: "轮播图列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "bannerList",
			},
		  },

		  {
			path: "fenzhiList",
			components: {
			  main: () => importPage("webSite/fenzhiList"),
			},
			meta: {
			  title: "分支机构列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "fenzhiList",
			},
		  },
		  {
			path: "fenzhiEdit",
			components: {
			  main: () => importPage("webSite/fenzhiEdit"),
			},
			meta: {
			  title: "分支机构列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "fenzhiList",
			},
		  },

		  {
			path: "companyStyle",
			components: {
			  main: () => importPage("webSite/companyStyle"),
			},
			meta: {
			  title: "公司风采列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "companyStyle",
			},
		  },
		  {
			path: "companyStyleEdit",
			components: {
			  main: () => importPage("webSite/companyStyleEdit"),
			},
			meta: {
			  title: "公司风采列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "fuzhulanmu",
			  tertiaryMenuId: "companyStyle",
			},
		  },
		  {
			path: "companyOverview",
			components: {
			  main: () => importPage("webSite/companyOverview"),
			},
			meta: {
			  title: "公司概况",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "companyOverview",
			},
		  },
		  {
			path: "liuyan",
			components: {
			  main: () => importPage("webSite/liuyan"),
			},
			meta: {
			  title: "联系我们留言列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "liuyan",
			},
		  },
		  {
			path: "liuyanEdit",
			components: {
			  main: () => importPage("webSite/liuyanEdit"),
			},
			meta: {
			  title: "联系我们留言列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "liuyan",
			},
		  },

		  {
			path: "hzfx",
			components: {
			  main: () => importPage("webSite/hzfx"),
			},
			meta: {
			  title: "合作方向列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "hzfx",
			},
		  },
		  {
			path: "hzfxEdit",
			components: {
			  main: () => importPage("webSite/hzfxEdit"),
			},
			meta: {
			  title: "合作方向列表-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "hzfx",
			},
		  },
		  {
			path: "statistic",
			components: {
			  main: () => importPage("webSite/statistic"),
			},
			meta: {
			  title: "数字统计列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "statistic",
			},
		  },
		  {
			path: "statisticEdit",
			components: {
			  main: () => importPage("webSite/statisticEdit"),
			},
			meta: {
			  title: "数字统计-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "statistic",
			},
		  },
		  {
			path: "lanmuList",
			components: {
			  main: () => importPage("webSite/lanmuList"),
			},
			meta: {
			  title: "栏目广告列表",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "lanmuList",
			},
		  },
		  {
			path: "lanmuEdit",
			components: {
			  main: () => importPage("webSite/lanmuEdit"),
			},
			meta: {
			  title: "栏目广告-编辑",
			  primaryMenuId: "webSite",
			  secondaryMenuId: "homelanmu",
			  tertiaryMenuId: "lanmuList",
			}
		},
	 ]
		
}

